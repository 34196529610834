import { template as template_bdd7f38f585e4070b365dd2a65579e21 } from "@ember/template-compiler";
const FKLabel = template_bdd7f38f585e4070b365dd2a65579e21(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
