import { template as template_6dcc28530e18450d9453e33960618445 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6dcc28530e18450d9453e33960618445(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
