import { template as template_602a1002e7ee4b5fa292b8ba81829836 } from "@ember/template-compiler";
const WelcomeHeader = template_602a1002e7ee4b5fa292b8ba81829836(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
