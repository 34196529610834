import { template as template_f613a6bae5f84bcb8c9b2e6a2eee3252 } from "@ember/template-compiler";
const FKText = template_f613a6bae5f84bcb8c9b2e6a2eee3252(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
